<template>
<el-container>
    <el-header>
      <div class="sider-logo">
        <img src="@/assets/images/logo.png" alt="">
        <span>订单确认</span>
      </div>
    </el-header>
    <el-main>
      <el-result icon="success" title="已支付" :subTitle="`￥${orderInfo.orderAmount}`">
        <template slot="extra">
          <div class="text-item">
            <label>订单编号：</label>{{orderInfo.orderNo}}
          </div>
          <div class="text-item">
            <label>商品名称：</label>{{goodsName}}
          </div>
          <div class="text-item">
            <label>商户名称：</label>{{orderInfo.receiveName}}
          </div>
          <div class="text-item">
            <label>付款方：</label>{{orderInfo.orderCompanyName}}
          </div>
        </template>
      </el-result>
    </el-main>
    <el-footer>
      <p>
        Copyright© {{year}} 上海灿谷网络科技有限公司 版权所有
        <a class="icp" href="http://www.miibeian.gov.cn/" style="color: #101D36;">沪ICP备17021964号</a>
      </p>
      <p>
        <img :src="require('@/assets/images/ga.png')" style="vertical-align: middle;margin-right:3px">
        <a class="icp" style="vertical-align: middle;color: #101D36;" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502008100">沪公网安备 31011502008100号</a>
      </p>
    </el-footer>
</el-container>
</template>

<script>
import {cashierOrderQuery} from '@/api'
export default {
  data(){
    return {
      orderNo:'',
      sign:'',
      orderInfo:{},
      goodsName:'',
      year:''
    }
  },
  created() {
    var now = new Date()
    this.year = now.getFullYear()
    this.orderNo=this.$route.query.orderNo || ''
    this.sign=this.$route.query.sign || ''
    this.getOrderInfo()
  },
  methods:{
    getOrderInfo(){
      cashierOrderQuery({
        orderNo:this.orderNo,
        sign:this.sign
      }).then(res=>{
        console.log(res)
        this.orderInfo=res
        this.goodsName=res.orderDetail[0].goodsName
      }).catch(err=>{
        this.$dialog.alert({
          message: err,
        })
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.sider-logo{
  line-height: 60px;
  img{
    vertical-align: middle;
  }
  span{
    padding-left: 20px;
  }
}
.el-header {
  background-color: #595767;
  line-height: 60px;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  padding-left:20px;
}
.el-result{
  padding-top:200px;
}
.text-item{
  font-size: 20px;
  display: flex;
  label{
    width: 100px;
    display: inline-block;
  }
}
.icp:hover{
  text-decoration: underline;
  cursor: pointer
}
.el-footer{
  font-size: 14px;
  height: 75px !important;
  padding-top:15px;
  width: 100%;
  position: fixed;
  bottom: 0px;
  text-align: center;
  background-color: #49484e;
  color: #bbb;
  a{
    color: #bbb !important;
  }
}
</style>